@font-face {
  font-family: 'Montserrat';
  src:
    local('Montserrat'),
    url('/public/fonts/Montserrat-Medium.ttf') format('opentype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src:
    local('Montserrat'),
    url('/public/fonts/Montserrat-Bold.ttf') format('opentype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  src:
    local('Montserrat'),
    url('/public/fonts/Montserrat-Black.ttf') format('opentype');
}
@font-face {
  font-family: 'Roboto';
  src:
    local('Roboto'),
    url('/public/fonts/Roboto-Medium.ttf') format('opentype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src:
    local('Roboto'),
    url('/public/fonts/Roboto-Bold.ttf') format('opentype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  src:
    local('Roboto'),
    url('/public/fonts/Roboto-Black.ttf') format('opentype');
}

html{
  padding: 0;
  margin: 0;
}
body {
  padding: 0;
  margin: 0;
  background-color: #edf1f4;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
@media only screen and (max-width: 600px) {
  body {
    background-color: #f8f8fa;
  }
}
@media only screen and (hover: none) and (pointer: coarse) {
  input:focus,
  select:focus,
  textarea:focus {
    font-size: 16px;
  }
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.MuiDatePickerToolbar-title,
.MuiPickersCalendarHeader-labelContainer,
.MuiPickersYear-yearButton {
  color: #07162F !important;
}
.MuiPickersYear-yearButton.Mui-selected{
  color: #FFFFFF !important;
}
